/* .no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-results-text {
  font-size: 2rem;
  font-weight: bold;
  color: #555;
  text-align: center;
  margin: 0;
} */


.no-results-container {
  margin: 3rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* height: 100%; */
  background: linear-gradient(to bottom right, #5f27cd, #341f97);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.no-results-text {
  /* width: 100%; */
  text-transform: uppercase;
  padding: 0.5rem 0;
  font-size: 2rem;
  /* font-weight: 100; */
  color: white;
  text-align: center;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}