
.marqueeForm {
  max-width: 400px;
  margin: 2rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
}

.inputBox {
  /* border: 1px solid red; */
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.nameInput,
.descInput {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.descInput {
  margin: 1rem 0;
}

.marqueeCreateBtn {
  border: none;
  outline: none;
  padding: 0.2rem 0.5rem;
  margin-top: 1rem;
  cursor: pointer;

  border: 1px solid #ccc;
  border-radius: 0.3rem;
}



/* MARQUEE TABLE LIST */
.marqueeList {
  margin: 2rem;
  padding: 1rem;
  max-width: 500px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.marqueeItem {
  display: flex;
  gap: 0.5rem;
  /* justify-content: space-evenly; */
}

.deleteIcon {
  cursor: pointer;
}