
.calls_btn_container {
  display: flex;
  
}

.buy_calls_btn,
.sell_calls_btn {
  /* border: 2px solid rgb(4, 114, 4); */
  padding: 0.5rem 1.5rem;
  background-color: #000;

  color: #fff;
  cursor: pointer;
}

.sell_calls_btn {
  border-left: none;
}