
.sliderCaption {

}

.topImageBannerContainer {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 565px; */
  /* height: 100%; */
  
}
.topBannerImg {
  height: 100%;
  width: 100%;
/* object-fit: fill; */
}

.badgeContainers {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.topStocksTableContainer {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSliderContainer {
  /* border: 2px solid red; */
  /* max-height: 400px; */
}