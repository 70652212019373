.container {
  /* width: 100%; */
  min-height: 450px;
  /* padding: 2rem; */
  color: white;
  /* background: #e3e3e3; */
  background-color: green;
  /* background: url('../../../assets/backgrounds//bluebg.jpg'); */
  border-radius: 8px;

  display: flex;
  align-items: center;

  position: relative;
}

.leftImageContainer,
.rightImageContainer {
  flex: 3;
  align-self: flex-end;
  /* padding: 0 1rem; */
  /* max-height: 450px; */
  max-width: 30%;
  /* overflow: hidden; */
  /* background-color: red; */
}

/* .leftImageContainer {
  position: absolute;
} */

.leftImage,
.rightImage {
  width: 100%;
  height: 100%;
  /* padding: 2rem; */
}

/* .rightImage {
  width: 100%;
} */

.middleContentContainer {
  flex: 5;
  padding: 0 2rem;
  /* color: #fff; */
  
}
.text_title {
  font-size: 2rem;
  font-weight: 400;
}
.middleContentContainer {
  padding-top: 2rem;
  text-align: justify;
  font-size: 1.2rem;
  font-weight: 300;
}

.topBanner_List {
  list-style: none;
  color: rgb(0, 238, 255);
  margin: 0.5rem 0;
}
.subtitle {
  font-size: 2rem;
  color: yellow;
}

.rightImageContainer {
}



/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .container {
    align-items: start;
  }
  .leftImageContainer,
  .rightImageContainer {
    display: none;
  }
}
