

.infoCards_Container {
  margin: 2rem 0;
  padding: 4rem 2rem;
}

.infoCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
}