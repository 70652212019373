.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.heading {
  font-size: 3rem;
  font-weight: bold;
  color: #f44336;
  margin-bottom: 1rem;
}

.message {
  font-size: 1.5rem;
  color: #444;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.5;
}
