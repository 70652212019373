.links_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}



/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .links_container {

  }
}