.body {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  text-align: center;
  margin: 0;
  padding: 0;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 50px auto;
  max-width: 400px;
}

.title {
  color: #e74c3c;
}

.text {
  color: #333;
}