.access-denied {
    text-align: center;
    background-color: #f2dede;
    padding: 30px;
    margin-top: 50px;
    border-radius: 5px;
}

.access-denied__title {
    font-size: 2em;
    color: #a94442;
    margin-bottom: 20px;
}

.access-denied__message {
    font-size: 1.5em;
    color: #a94442;
}