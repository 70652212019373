.number_Input_Control,
.select_Input_Control,
.html_btn,
.previous_pattern_select_Input {
  text-align: center;
  /* height: 20px; */
  width: 40x;
  border-radius: 4px;
  outline: none;
  /* border: none; */
  border: 1px solid #ccc;
  padding: 2px;
}

.html_btn {
  cursor: pointer;
  /* background-color: red; */
  color: white;
}

.number_Input_Control {
  padding: 0;
  width: 35px;
  font-size: 14px;
  /* border: 1px solid; */
}