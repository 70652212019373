

.title {
  text-align: center;
}

.carouselContainer {
  /* width: 1100px; */
  width: 100%;
  min-height: 350px;
  /* padding: 0 2rem; */
  /* border: 4px solid yellow; */
  /* border-left: 30px solid rgba(0, 0, 0, 0.2);
  border-right: 30px solid rgba(0, 0, 0, 0.2); */
  

  /* display: flex;
  flex-direction: row;
  overflow: hidden; */
}

.carouselItem {
  /* border: 2px solid black; */
  /* background-color: green; */
  /* padding: 5px; */
  /* width: 200px; */
  
  /* margin: 0 2rem; */
  /* flex: 0 0 25%; */
}

.imageContainer {
  /* width: 200px; */
  width: 100%;
  height: 250px;
  /* overflow: hidden; */
}

.image {
  width: 100%;
  height: 100%;
}

.subtitleText {
  min-height: 60px;
  padding: 14px 8px 4px;
  /* width: 100%; */
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: #339502;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderCaption {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textStrikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
}


/* If screen size is 600px wide, or less, set the font-size of <div> to 30px */
@media screen and (max-width: 1200px) {
  .carouselContainer {
    max-width: 1100px;
  }
}
@media screen and (max-width: 1000px) {
  .carouselContainer {
    max-width: 900px;
  }
}

@media screen and (max-width: 800px) {
  .carouselContainer {
    max-width: 700px;
  }
}

@media screen and (max-width: 600px) {
  .carouselContainer {
    max-width: 500px;
  }
}