
.NewFooter {
  /* background-color: beige; */
  padding: 4rem 2rem 1rem;
  
}


.topContainer {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(63, 177, 5, 0.40);;
}

/* FOOTER SECTION COMMON STYLING */
.footerSection {
  padding: 2rem;
}
.sectionTitle {
  color: #083A5E;
  margin-bottom: 1rem;
  text-align: center;
}

/* SOCIAL LINKS SECTION */
.socialLinksContainer {
  flex: 1;
  
}
.usefulLinksContainer {
  flex: 1;
  
}

.subscribeContainer {
  flex: 1;
}

.socialLinksContainer h1 {
  margin-bottom: 1rem;
}
.socialLinksContainer p {
  min-width: 250px;
  color: #909090;
  text-align: justify;
  margin-bottom: 2rem;
}

.socialIcons {
  display: flex;
  gap: 2rem;
}


/* USEFULL LINKS & MENU SECTION  */
.listWrapper {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  gap: 1rem;
}

.linksList {
  list-style: none;
  
}

.link {
  color: #444;
  
}
.linkItem {
  margin: 0.2rem 0;
  /* padding-bottom: 0.5rem; */
  
  transition: all .5s;
}

.linkItem:hover {
  /* border-bottom: 1px solid; */
  color: blue;
}


/* SUBSCRIBE SECTION */
.subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.emailInput,
.subscribeButton {
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.emailInput {
  
  padding-left: 1rem;
  background: #F0F0F0;
}

.subscribeButton {
  background-color: #339502;
  color: white;
}


/* COPYRIGHT */
.copyrightContainer {
  padding: 1rem;
  text-align: center;
}