.image-slider {
  position: relative;
  max-width: 100%;
  /* height: 100%; */
  /* max-height: 400px; */
  overflow: hidden;
}

.slider-image {
  /* width: 100%; */
  height: auto;
  height: 100%;
  display: block;
  object-fit: cover;
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 2;
}

.prev-btn {
  left: 0;
}

.next-btn {
  right: 0;
}
