
.searchListContainer {
  /* width: 100%; */
  height: 300px;
  /* border: 1px solid blue; */
  position: absolute;
  margin-top: 4rem;
  z-index: 999;
}

/* Search List menu */
.searchList {
  list-style: none;
  /* margin: 16px;
  padding: 8px 0; */
  /* box-shadow: 2px 2px 5px 1px #888; */
  background-color: white;
  

  /* position: absolute; */
  max-height: 300px;
  min-width: 300px;
  overflow: auto;
  /* margin-top: 4rem; */
  border: 1px solid #ccc;
  border-radius: 4px;
  /* z-index: 999; */
}

.searchListItem {
  cursor: pointer;
  color: #555;
  padding: 2px 12px;

  /* background-color: #4dcb0f; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchListItem:hover {
  background-color: #555;
  color: white;
}
.searchListItem:hover span {
  color: white !important;
}

/* DATE */
.date_container {
  /* width: 100%; */
  padding: 0.5rem 1rem;
  border: 3px solid #4dcb0f;
  border-radius: 1.5rem;
  /* height: 40px; */
  margin-left: 0.5rem;
  
}

.date_input {
  /* visibility: hidden; */
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}