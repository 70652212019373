
.mainContainer {
  /* width: 100vw; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  max-height: 600px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  padding: 2rem 4rem;

  /* border: 1px solid red; */
}

.textContainer {
  flex: 1;
  padding: 0 2rem;
  max-width: 400px;
}

h2.title {
  font-size: 2rem;
}

.description {
  margin: 1rem 0 1.5rem;
  color: rgba(86, 86, 86, 1);
  text-align: justify;
  font-weight: 300;
}

.ctaBtn {
  outline: none;
  border: none;
  padding: 0.4rem 1.5rem;
  background-color: rgba(11, 218, 81, 1);
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  /* font-size: 1.1rem; */
  letter-spacing: 2px;
  cursor: pointer;
}

.imageContainer {
  flex: 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.image {
  width: 100%;
  object-fit: contain;
  /* border: 1px solid red; */
}



/* MEDIA QUERIES */
@media only screen and (max-width: 900px) {
  .innerContainer {
    flex-direction: column;
    max-height: none;
    gap: 2rem;
  }

  .textContainer {
    max-width: none;
  }
}