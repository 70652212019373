
/* Search List menu */
.searchList {
  list-style: none;
  /* border: 1px solid black; */
  border-radius: 4px;
  margin: 6px;
  padding: 8px 0;
  /* box-shadow: 2px 2px 5px 1px #888; */
  background-color: white;

  position: absolute;
  max-height: 300px;
  max-width: 250px;
  overflow: auto;
  margin-top: 3rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchListItem {
  cursor: pointer;
  color: #555;
  padding: 2px 12px;
}

.searchListItem:hover {
  background-color: #555;
  color: white;
}

/* TOP BAR, TAGS, MENUS */
.topBar {
  max-height: 80px;
  padding: 4px 2%;
  border-bottom: 1px solid #ccc;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.leftMenu {
  /* flex: 1; */
}

.leftMenuItem {
  background-color: #339502;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 8px;
  margin-right: 1rem;
  /* border: 1px solid #888; */
  border-radius: 3px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.leftMenuItem:hover {
  background-color: #26570e;
}

.rightMenu {
  /* background-color: red; */
  /* flex: 1; */
}
.rightMenuItem {
  color: #888;
  font-weight: 300;
  padding: 0 4px;
  border-radius: 4px;
  /* font-weight: bold; */
  font-size: 14px;
  margin-right: 1rem;
  text-transform: uppercase;
}

.rightMenuItem:hover {
  background-color: rgba(0,0,0,0.5);
  color: white;
}

/* .menu_active {
  background-color: red !important;
  border: 3px solid green !important;
} */
.navMenuItem {
  width: 100%;
  height: 100%;
  margin: 1rem;
  color: #333;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
}
.navMenuItem:hover {
  /* color: white; */
}
.NavLink:hover {
  background-color: #eee;
  /* color: white; */
}


/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .topBar {
    justify-content: center;
  }
}