.outer_container {
  margin-bottom: 2rem;
  padding: 2rem;
  min-height: 300px;
  color: #fff;
  /* background-image: url('../../../assets/backgrounds/stepsbg.jpg'), url('../../../assets/backgrounds/peoplebg.jpg'), linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0));
   */

  background-image: url('../../../assets/backgrounds/stepsbg.jpg'), url('../../../assets/backgrounds/peoplebg.jpg');
  background-position: center bottom, left top;
  background-repeat: no-repeat;
}

.upper_caption {
  background-color: yellow;
  padding: 2px 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  /* text-transform: capitalize; */
}

.main_title {
  font-size: 3rem;
  /* font-weight: bold; */
}

/* ANIMATIONS */
.main_title_NOTACTIVE
{
   /* text-transform: uppercase; */
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



.subtitle {
  font-size: 3rem;
  font-weight: 200;
  padding-bottom: 4px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #fff;
}

.steps_container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 4px;
}

.step_container {
  /* margin: 1rem; */
  padding: 0 1rem;
  font-weight: 200;
  /* max-width: 30%; */
}
.step_title {
  font-size: 3rem;
  font-weight: bold;
}
.steps_content {
  font-size: 2rem;
}

.icons_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3rem;
}

.icon_image {
  height: 40px;
}
.youtube_icons {
  /* margin: 0 4px; */
  max-height: 35px;
}


@media (max-width: 1100px) {
  .icon_image {
    max-width: 120px;
    height: auto;
  }

  .main_title,
  .subtitle,
  .step_title {
    font-size: 2.5rem;
  }
  
  
}

@media (max-width: 800px) {
  .main_title {
    /* font-size: 1.5rem; */
    font-weight: 400;
  }
  
  .step_container {
    /* background-color: red; */
    padding: 0 0.5rem;
  }
  .step_title {
    font-size: 2rem;
  }
  .steps_content {
    font-size: 1.5rem;
  }
  
  .icons_container {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .steps_container {
    flex-direction: column;
    gap: 8px;
  }
  .step_container {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 8px;
  }
  .main_title {
    font-size: 2rem;
  }
}