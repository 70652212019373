
.outer_container {
  max-width: 220px;
  /* margin: 1rem 0; */
  border-radius: 6px;
  border: 1.5px solid #E2E8F0;
}

.container {
  /* max-width: 220px; */
  /* margin: 0.5rem 0; */
  
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: space-evenly;
  padding-top: 0.2rem;
  /* background: rgba( 51, 149, 2, 0.25 ); */
/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
/* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}



.share_text {
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;

  /* max-width: 220px; */
  /* width: 100%; */
  padding: 4px ;
  border-radius: 10px;
  /* background: rgba( 51, 149, 2, 0.25 ); */
}

