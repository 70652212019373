
.outer_container {
  min-height: 300px;
  /* background-color: blueviolet; */
  /* padding: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_container {
  display: flex;
  /* flex-wrap: wrap; */
}

.video_caption_container {
  /* flex: 1; */
  padding: 2rem;
  
}
.video_caption {
  font-size: 24px;
}

.youtube_video_container {
  /* flex: 1; */
  min-width: 50%;
  height: 100%;
  
  /* padding: 1rem; */
}



/* MEDIA QUERIES */
@media screen and (max-width: 800px) {
  
  .inner_container {
    flex-direction: column;
    padding: 2rem;
  }
}



